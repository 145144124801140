export const environment = {
  production: false,
  apiUrl: "https://eu.phase4.api.deepersignals.com/api/",
  recaptchaSiteKey: "",
  stripeKey: "jjj",
  disableRecaptcha: true,
  enableHotjar: false,
  enableClarity: false,
  intercom: {
    enabled: true,
    api_url: "https://api-iam.intercom.io/",
    app_id: "rn7d83f8",
  },
  ravenDsn: "",
  nonce: "RcK9WZrUnQ6HGKFUn33ACRGMuI97ZKWYW2HozREZVdhg2JAMMN0/jkj34ZpGeXLM",
  crowdinKey: "a0cc060927c83821c42dfe8qc3a",
  pixel: {
    enabled: false,
    trackOnLoad: false,
    key: "",
  },
  enableFaroGraphana: false,
};
