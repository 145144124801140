export enum DsIconEnum {
  VerticalDots = "vertical-dots", //svg
  User = "user", //fa and hero svg
  Email = "email", //hero svg (use Envelope for fa)
  Envelope = "envelope",
  Lock = "lock",
  LockClosed = "lock-closed", //hero svg (Use Lock with Solid weight)
  UserCircle = "user-circle", //fa and hero svg
  DocumentDuplicate = "document-duplicate", //hero svg
  DocumentReport = "file-chart-column", //fa and hero svg
  Trash = "trash", //fa and hero svg
  TrashCan = "trash-can",
  UserAdd = "user-plus", //fa and hero svg
  UserRemove = "user-minus", //fa and hero svg
  CloudDownload = "cloud-download", //fa and hero svg
  Clipboard = "clipboard", //fa and hero svg
  ChevronDown = "chevron-down", //fa and hero svg
  ChevronUp = "chevron-up", //fa and hero svg
  ChevronRight = "chevron-right", //fa and hero svg
  ChevronLeft = "chevron-left", //fa and hero svg
  Copy = "copy", //fa and hero svg
  Folder = "folder", //fa and hero svg
  FolderAdd = "folder-plus", //fa and hero svg
  FolderOpen = "folder-open", //fa and hero svg
  FolderCheck = "folder-check", //fa and svg
  FolderClosed = "folder-closed", //fa and svg
  ThumbUp = "thumb-up", //fa and hero svg
  ThumbDown = "thumb-down", //fa and hero svg
  Home = "home-blank", //fa and hero svg
  Code = "code", //fa and hero svg
  ArrowSmRight = "arrow-sm-right", //hero svg (use ArrowRight for fa)
  ArrowSmLeft = "arrow-sm-left", //hero svg (use ArrowLeft for fa)
  ArrowRight = "arrow-right", //fa and hero svg
  Refresh = "refresh", //fa and hero svg
  Info = "info", //hero svg (use CircleInfo for fa)
  Logout = "logout", //hero svg (use ArrowRightFromBracket for fa)
  Height = "height", //svg
  PencilAlt = "pen-to-square", //fa and hero svg
  Minus = "minus", //fa and hero svg
  PlusSm = "plus-sm", //hero svg
  Plus = "plus", //fa and hero svg
  ExclamationCircle = "circle-exclamation", //fa and hero svg
  CharSimple = "chart-simple", //fa
  ChartBar = "chart-bar", //hero svg
  Calendar = "calendar", //fa and hero svg
  Assessment = "chart-column", //fa and hero svg
  Users = "users",
  CircleCheck = "circle-check", //fa and hero svg
  Pencil = "pencil", //fa and hero svg
  X = "x", //fa and hero svg (use Xmark for fa)
  Menu = "menu", //fa and hero svg (use Bars for fa)
  UserGroups = "user-groups", //svg
  DownloadOutline = "download-outline", //hero svg (use ArrowDownToBracket for fa)
  UserGroup = "user-group", //fa and hero svg
  Puzzle = "puzzle", //hero svg (use PuzzlePiece for fa)
  LightBulb = "lightbulb",
  LightBulbDash = "light-bulb", //hero svg (use LightBulb for fa)
  CubeTransparent = "cube-transparent", //hero svg
  ChartPie = "chart-pie", //fa and hero svg
  Share = "share", //hero svg (use ShareNodes for fa)
  DocumentText = "file-word", //fa and hero svg
  DocumentSearch = "file-magnifying-glass", //fa and hero svg
  Lines = "lines", //svg
  ArrowBoth = "arrow-left-right", //fa and svg
  Academic = "graduation-cap", //hero svg (use GraduationCap for fa)
  LongArrowRight = "long-arrow-right", //svg
  Logo = "logo", //svg
  LongerRightArrow = "longer-right-arrow", //svg
  Eye = "eye", //fa and hero svg
  EyeSlash = "eye-slash",
  EyeOff = "eyeOff", //hero svg (use EyeSlash for fa)
  ClipboardCheck = "clipboard-check", //fa and hero svg
  Achievement = "achievement", // fa kit
  Change = "change", // fa kit
  Expedience = "expedience", // fa kit
  Humility = "humility", // fa kit
  Independence = "independence", // fa kit
  Intuition = "intuition", // fa kit
  Learning = "learning", // fa kit
  Leisure = "leisure", // fa kit
  Power = "bolt", //fa and svg
  Principles = "principles", // fa kit
  Relationships = "relationships", // fa kit
  OnePrimaryFilledDot = "one-primary-filled-dot", //svg
  TwoPrimaryFilledDot = "two-primary-filled-dot", //svg
  ThreePrimaryFilledDot = "three-primary-filled-dot", //svg
  OneFilledDot = "one-filled-dot", //svg
  TwoFilledDot = "two-filled-dot", //svg
  ThreeFilledDot = "three-filled-dot", //svg
  TriangleExclamation = "triangle-exclamation",
  ExclamationTriangle = "exclamation-triangle", //svg
  Check = "check", //fa and hero svg
  OneFilledMenu = "one-filled-menu", //svg
  TwoFilledMenu = "two-filled-menu", //svg
  ThreeFilledMenu = "three-filled-menu", //svg
  ArrowUpRightFromSquare = "arrow-up-right-from-square", //fa and svg
  ArrowLeft = "arrow-left", //fa and hero svg
  Link = "link", //fa and hero svg
  Twitter = "x-twitter", //fa and svg
  UserGroups2 = "user-groups-2", //svg
  Flag = "flag", //fa and hero svg
  Hello = "hand-wave", //fa and svg
  DsNew = "ds-new", //svg
  PresentationChartLine = "PresentationChartLine", //hero svg
  Cog8Tooth = "Cog8Tooth", //svg
  ChatBubbleBottomCenterText = "ChatBubbleBottomCenterText", //svg
  GroupAssign = "user-check", //fa and svg
  GroupUnassign = "user-xmark", //fa and svg
  ShieldExclamation = "shield-exclamation", //fa and hero svg
  ArrowsRightLeft = "arrow-right-arrow-left", //fa and svg
  ArrowLongDown = "ArrowLongDown", //svg
  ArrowLongUp = "ArrowLongUp", //svg
  Sparkles = "sparkles", //fa and svg
  LinkedIn = "linkedin", //fa and svg
  Instagram = "instagram", //fa and svg
  TwitterFilled = "square-x-twitter", //fa and svg
  Scale = "scale", //hero svg
  Cube = "cube", //fa and hero svg
  CubeActive = "CubeActive", //svg
  CubeCustomActive = "CubeCustomActive", //svg
  CubeCustomInactive = "CubeCustomInactive", //svg
  // LockSolid = "LockSolid", // Use Lock //Completely moved
  Tradition = "tradition", // fa kit
  CloseSidebar = "arrow-left-from-line", //svg
  Clock = "clock-three", //fa and svg
  CoachStarter = "dice-d4", //fa and svg
  CoachPlan = "dice-d8", //fa and svg
  CoachPlanPro = "dice-d10", //fa and svg
  CoachPlanMax = "dice-d12", //fa and svg
  CheckBadgeSolid = "check-badge-solid", // hero svg (use CheckBadge with weight solid for fa)
  CheckBadge = "badge-check",
  CreditCard = "credit-card", //fa and hero svg
  Enterprise = "dice-d20", //fa and svg
  Upload = "arrow-up-from-bracket", //fa and svg
  MagnifyingGlassCircle = "magnifying-glass-circle", //hero svg
  MagnifyingGlassMinus = "magnifying-glass-minus", //hero svg
  MagnifyingGlassPlus = "magnifying-glass-plus", //hero svg
  MagnifyingGlass = "magnifying-glass", //hero svg
  GlobeAlt = "globe-alt", // hero svg (use Globe for fa)
  Globe = "globe",
  QuoteLeft = "quote-left", //fa and svg
  Star = "star", //fa and hero svg
  StarSolid = "star-solid", //hero svg (ue Star using weight Solid for fa)
  Filter = "filter", //fa and hero svg
  FilterSolid = "FilterSolid", //hero svg (use Filter using weight solid for fa)
  AccountCircle = "account_circle", //hero svg
  UsersPlus = "UsersPlus", //svg
  AccountTree = "account_tree", //hero svg
  Building = "building", //fa and
  FaceDisappointed = "face-disappointed", //fa and svg
  FaceLaugh = "face-laugh-beam", //fa and svg
  ArrowUpTray = "arrow-up-to-bracket", //fa and hero svg
  DocumentArrowDown = "file-arrow-down", //fa and hero svg
  CoreDriversAssessmentIcon = "core-drivers-assessment-icon", // fa kit
  CoreValuesAssessmentIcon = "core-values-assessment-icon", // fa kit
  EllipsisVertical = "ellipsis-vertical", //fa and hero svg
  AdminDashboardSolid = "AdminDashboardSolid", //svg
  AdminDashboard = "AdminDashboard", //svg
  Teams = "people-simple", //fa and svg
  AllAssessments = "message-question", //fa and svg
  Libraries = "rectangle-history", //fa and svg
  Analytics = "chart-line-up", //fa and svg
  Play = "play", //fa and hero svg
  PlaySolid = "PlaySolid", //svg
  BarsStaggered = "bars-staggered", //fa and svg
  ArrowUpDown = "angles-up-down", //fa and svg
  Competencies = "Competencies", //svg
  Insights = "lightbulb-on", //fa and svg
  DynamoCards = "rectangle-vertical-history", //fa and svg
  //Skills report
  AnalyticsEvaluator = "analytics-evaluator", // fa kit
  OldAnalyticsEvaluator = "analytics_evaluator", //svg
  BehavioralScientist = "behavioral-scientist",
  OldBehavioralScientist = "behavioral_scientist", //svg
  OldChangeManager = "change_manager", //svg
  ChangeManager = "change-manager", // fa kit
  Consultant = "consultant", // fa kit
  DataReportingAnalyst = "data-reporting-analyst", // fa kit
  OldDataReportingAnalyst = "data_reporting_analyst", //svg
  DataScientist = "data-scientist", // fa kit
  OldDataScientist = "data_scientist", //svg
  DataSteward = "data-steward", // fa kit
  OldDataSteward = "data_steward", //svg
  DesignEngineer = "design-engineer", // fa kit
  OldDesignEngineer = "design_engineer", //svg
  GovernanceEthicsSteward = "governance-ethics-steward", // fa kit
  OldGovernanceEthicsSteward = "governance_ethics_steward", //svg
  KpiMetricsAnalyst = "kpi-metrics-analyst", // fa kit
  OldKpiMetricsAnalyst = "kpi_metrics_analyst", //svg
  ProductManager = "product-manager", // fa kit
  OldProductManager = "product_manager", //svg
  ProjectManager = "project-manager", // fa kit
  OldProjectManager = "project_manager", //svg
  TextAnalysts = "text-analysts", // fa kit
  OldTextAnalysts = "text_analysts", //svg
  WorkforceModeller = "workforce-modeller", // fa kit
  OldWorkforceModeller = "workforce_modeller", //svg
  //HRSkills report
  OrganisationalAcumen = "organisational-acumen", // fa kit
  OldOrganisationalAcumen = "organisational_acumen", //svg
  StakeholderManagement = "stakeholder-management", // fa kit
  OldStakeholderManagement = "stakeholder_management", //svg
  Storytelling = "storytelling", // fa kit
  HumanCentredDesign = "human-centred-design", // fa kit
  OldHumanCentredDesign = "human_centred_design", //svg
  ExImplementation = "ex-implementation", // fa kit
  OldExImplementation = "ex_implementation", //svg
  DigitalLiteracy = "digital-literacy", // fa kit
  OldDigitalLiteracy = "digital_literacy", //svg
  AnalyticalThinking = "analytical-thinking", // fa kit
  OldAnalyticalThinking = "analytical_thinking", //svg
  WorkforcePlanning = "workforce-planning", // fa kit
  OldWorkforcePlanning = "workforce_planning", //svg
  DataAnalysis = "data-analysis", // fa kit
  OldDataAnalysis = "data_analysis", //svg
  Reward = "reward", // fa kit
  ThreeVerticalLines = "ThreeVerticalLines", //svg
  Crowdin = "crowdin", //svg
  LightBolt = "power", // fa kit
  Candid = "candid", // fa kit
  Considerate = "considerate", // fa kit
  Curious = "curious", // fa kit
  Disciplined = "disciplined", // fa kit
  Driven = "driven", // fa kit
  Flexible = "flexible", // fa kit
  LaidBack = "laidBack", // fa kit
  Outgoing = "outgoing", // fa kit
  Passionate = "passionate", // fa kit
  Pragmatic = "pragmatic", // fa kit
  Reserved = "reserved", // fa kit
  Stable = "stable", // fa kit
  DefaultAssessment = "default-assessment-icon", //fa and svg kit
  PaperPlane = "paper-plane", //fa and svg
  PaperPlaneSolid = "paper-plane-solid", //svg
  WavePulse = "wave-pulse", //fa and svg
  RectangleHistory = "rectangle-history", //fa and svg
  Timer = "timer", //fa and svg
  PeopleSimple = "PeopleSimple", //svg
  Bell = "bell", //fa and hero svg
  NoSymbol = "no-symbol", //hero svg
  Feather = "feather", //fa and svg
  SquarePollBars = "square-poll-bars", //svg
  Tag = "tag", //fa and svg
  FACheck = "FACheck", //fa and svg
  ThreeColumns = "columns-3", //fa and svg
  BarsFilter = "bars-filter", //fa and svg
  LightBulbOn = "lightbulb-on",
  BuildingOffice2 = "buildings", //fa and hero svg
  NewsPaper = "newspaper",
  RectangleVerticalHistory = "rectangle-vertical-history", //fa and svg
  Dot = "dot", //svg
  RotateLeft = "rotate-left", //fa and svg
  AllUsers = "user-group-simple", //fa and svg
  Retweet = "retweet",
  Cog = "cog",
  ObjectColumn = "objects-column",
  GraduationCap = "graduation-cap",
  MessageQuestion = "message-question",
  CircleInfo = "circle-info",
  PuzzlePiece = "puzzle-piece",
  ChartSimple = "chart-simple",
  Brain = "brain",
  FileArrowDown = "file-arrow-down",
  ArrowRightFromBracket = "arrow-right-from-bracket",
  LightbulbCflOn = "lightbulb-cfl-on",
  ArrowDownToLine = "arrow-down-to-line",
  Circle = "circle",
  ShareNodes = "share-nodes",
  ArrowTurnDownLeft = "arrow-turn-down-left",
  Clone = "clone",
  Xmark = "xmark",
  SquarePollVertical = "square-poll-vertical",
  PeopleGroup = "people-group",
  LassoSparkles = "lasso-sparkles",
  Archive = "archive",
  Pen = "pen",
  FloppyDisk = "floppy-disk",
  UserTie = "user-tie",
  Loader = "loader",
  BadgeCheck = "badge-check",
  CheckDouble = "check-double",
  ArrowsRotate = "arrows-rotate",
  ChevronsUp = "chevrons-up",
  EnvelopeDot = "envelope-dot",
  ChartScatter3d = "chart-scatter-3d",
  QuestionCircle = "question-circle",
  Key = "key",
  ArrowDownToBracket = "arrow-down-to-bracket",
  Bars = "bars",
  ArrowDown = "arrow-down",
  ArrowUp = "arrow-up",
  FileImport = "file-import",
  Collapse = "collapse", //custom icon
  BlockQuestion = "block-question",
  AlbumCollection = "album-collection",
  ArrowsLeftRight = "arrows-left-right",
  ShareAlt = "share-alt",
  GirpDotsVertical = "grip-dots-vertical",
}

export enum DsIconWeightEnum {
  Solid = "solid",
  Regular = "regular",
  Light = "light",
  Thin = "thin",
  Kit = "kit",
  DuotoneKit = "kit-duotone",
  Brands = "brands",
}
